<template>
  <div>
    <v-form @submit.prevent="checkSubmit" :id="`check-create-admin-form`">
    <v-dialog
      v-model="dialog"
      :width="dialogSize"
      scrollable
    >
     <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="#745CA8"
            dark
            v-bind="attrs"
            v-on="on"
        >
            <v-icon>mdi-plus</v-icon>
            Create
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
            <b>เพิ่มผู้ดูแล</b>
            <v-spacer></v-spacer>
            <v-btn icon @click="clear() " >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
  
          <v-row class="py-3" >
            <v-col cols="3" class="py-8">
              <h3>CMU Account</h3>
            </v-col>
            <v-col>
              <v-text-field
                label="CMU Account"
                v-model="adminEmail"
                outlined
                :rules="[rules.adminEmail]"
                type="text"
              >
                E-mail
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              
              <v-row>
                <v-col cols="3"><h3>ขอบเขตรับผิดชอบ</h3></v-col>
                <v-col cols="8">
                  <v-radio-group style="margin-top: -0.5rem;" v-model="selectFac">
                    <v-radio v-if="selectOption === 'CMU'" name="selectCMU" label="ระดับมหาวิทยาลัย" :value="0"></v-radio>
                    <v-radio v-if="selectOption !== 'CMU' && selectOption !==''" name="selectFac" :label="`ระดับคณะ ${selectOption}`" :value="1"></v-radio>
                    <v-radio v-if="selectOption !== 'CMU'" name="selectCourse" label="ระดับหลักสูตร" :value="2"></v-radio>                
                  </v-radio-group>
                </v-col>
              </v-row>
              
              <v-row v-if="selectFac==2">
                <v-col cols="3"></v-col>
                <v-col cols="8">
                    <v-checkbox
                      style="margin-top: -2rem; margin-left: 1.5rem;"
                      v-model="checkAll"
                      @change="selectAll($event)"
                      label="เลือกทั้งหมด"
                      value="checkAll"
                    ></v-checkbox>
                </v-col>
                
              </v-row>
              <v-row v-if="selectFac==2">
                <v-col cols="4"></v-col>
                <v-col cols="8" style="margin-top: -2rem;">
                  <template v-for="(course,i) in courseArr" >
                      <v-checkbox
                        class="mt-0"
                        v-model="selected"
                        :label="String(course.title)"
                        :value="course.value"
                        :key="`checkbox-${i}`"
                      ></v-checkbox>
                      <div class="break" :key="`break-${i}`"></div>
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="dark"
            text
            @click="clear()"
          >
            ยกเลิก
          </v-btn>
          <v-btn
            color="#69A5FF"
            class="white--text"
            @click="checkSubmit()"
          >
            บันทึก
          </v-btn>
          
        </v-card-actions> 
        
        
      </v-card>
    </v-dialog>
    
    
    </v-form>
  </div>
</template>

<script>


    export default {
      name: 'CreateAdminModal',
      props: {
        selectOption : String ,
        adminCMU : Boolean,
        adminFac : Boolean,
        courseData: {
          type: Object
        },
        openModal: Boolean
      },
      computed: {

      },
      data () {
        return {
          dialog: false,
          dialogSize: 700,
          selected: [],
          courseArr: [],
          section: [] ,
          selection : [] ,
          adminEmail : "" ,
          checkAll :  true ,
          selectFac : this.selectOption === "CMU"? 0 : 1,
          rules: {
              adminEmail: value => {
                  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  return pattern.test(value) || 'Invalid e-mail.'
              },
          },
        }
      },
      created (){
        // console.log("selectOption" , this.selectOption)
        this.createCourseArr(this.courseData)
      },
      components: {
      },
     
      watch: {
        courseData: function (newVal) {
          this.createCourseArr(newVal)
        },
        openModal: function () {
          this.dialog = true
          // console.log("console.log(this.selectOption)",this.selectOption)
        },
        dialog: function () {
          this.$emit('modalClose', false)
        },
       
        
        
      },
      methods: {
       createCourseArr (courseData) {
          // console.log("courseData" , courseData)
          if(courseData){
            this.courseArr = []
            for(let i=0;i<courseData.length;i++){
              this.courseArr.push({
                title : String(courseData[i].prefix) + ' ' +String(courseData[i].title) , 
                value : courseData[i].prefix
              })
            }
            // console.log("select :>" , this.courseArr)
          }
        },
        checkSubmit() {
          this.$emit("handleAdd" , this.adminEmail , this.selected , this.selectFac)
          this.clear()
        },
        clear(){
          this.dialog = false
          this.selected = [] ;
          this.selection = [] ;
          this.checkAll = ""  ;
          this.adminEmail = "" ;
        },
        selectAll(event){
          // console.log("event :>" , event)
            let selected = []
            if (event) {
              this.courseArr.forEach(function (course) {
                selected.push(course.value)
              })
            }
            this.selected = selected
        }
       
       
      },
    }
</script>